export const guardianTestValues = {
  botField: '',
  guardian: {
    firstName: 'Steve',
    lastName: 'Ariss',
    email: 'steve@arissberg.com',
    phone: '416 508 8245',
    birthday: '1975-01-01',
    address: {
      // formattedAddress: '2846 Bucklepost Crescent, Mississauga, ON L5N 1X5, Canada',
      // coordinates: [-79.74220009999999, 43.571468],
      street: '2846 Bucklepost Crescent',
      unit: '23',
      city: 'Mississauga',
      province: 'Ontario',
      postal: 'L5N 1X5',
    },
    usedEmergencyCare: false,
    umbrellaEmployee: false,
    worksOutsideHome: false,
    subscribe: false,
  },
}

export const childTestValues = {
  firstName: 'Dylan',
  lastName: 'Ariss',
  birthday: '2015-06-25',
  currentlyEnrolled: true,
}

export const facilityChoiceTestValues = {
  // searchAddress: {
  //   formattedAddress: '310 Limeridge Rd W, Hamilton, ON L9C 2V2, Canada',
  //   coordinates: [43.224051, -79.89858339999999],
  // },
  // firstFacilityChoice: 'Ray Lewis',
  // secondFacilityChoice: 'Lawfield',
}
