import { Component } from 'react'
import { connect } from 'formik'

import { flattenObject } from '../utils'

// Based on this gist: https://gist.github.com/dphrag/4db3b453e02567a0bb52592679554a5b
class ErrorFocus extends Component {
  static defaultProps = {
    block: 'center', // Vertical Alignment - start, center, end, nearest
    inline: 'nearest', // Horizontal Alignment - start, center, end, nearest
    behavior: 'smooth',
    focusDelay: 800,
  }

  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik
    // Need to flatten the Formik error object in case we've nested the fields
    const keys = Object.keys(flattenObject(errors))

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const { focusDelay, behavior, block, inline } = this.props

      const selector = `[name="${keys[0]}"]`
      const errorElement = document.querySelector(selector) || document.querySelector('form')

      console.log('scroll to this error', errorElement)

      if (errorElement) {
        errorElement.scrollIntoView({
          behavior,
          block,
          inline,
        })
        // scrollTo(errorElement)
        this.timeout = setTimeout(() => errorElement.focus(), focusDelay)
      }
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    return null
  }
}

export default connect(ErrorFocus)
