import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'

import { Loader as GMapsLoader } from '@googlemaps/js-api-loader'
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import getGeocodePart from '../utils/geocodeUtils'

import { listBox, listItem, listItemDarken, googleLogo, InputError } from './FormControls'

let cachedVal = ''

// 38 = up arrow
// 40 = down arrow
// 13 = enter
// 27 = escape
// 9 = tab
const acceptedKeys = [38, 40, 13, 27, 9]

const PlacesAutocomplete = ({ onGeoData, ...props }) => {
  const [currIndex, setCurrIndex] = useState()
  const [gMap, setGmap] = useState('LOADING')

  const {
    ready,
    init,
    value,
    suggestions: { loading, status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'ca',
      },
    },
    debounce: 300,
    initOnMount: false,
  })

  // Load Google maps
  useEffect(() => {
    const loader = new GMapsLoader({
      apiKey: process.env.GATSBY_GOOGLE_API_KEY,
      libraries: ['places'],
    })

    loader.load().then(() => {
      init()
    })
  }, [init])

  /** Set the intial state if we already have a value passed in */
  useEffect(() => {
    if (!props.value) return
    setValue(props.value, false) // don't request data from the api
  }, [props.value, setValue])

  const hasSuggestions = status === 'OK'

  const dismissSuggestions = () => {
    setCurrIndex(null)
    clearSuggestions()
  }

  const ref = useRef()
  useOnclickOutside(ref, dismissSuggestions)

  const handleInput = e => {
    // Update the keyword of the input element
    setValue(e.target.value)
    cachedVal = e.target.value
    // Update Formik
    // props.form.setFieldValue(props.field.name, e.target.value)
    props.onChange(e)
  }

  const handleSelect =
    ({ description, structured_formatting }) =>
    async () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter as "false"
      setValue(structured_formatting.main_text, false)
      // setValue(description, false)

      dismissSuggestions()
      // Get address parts
      try {
        const [geocode] = await getGeocode({ address: description })
        // const formatted_address
        // const street
        const coordinates = await getLatLng(geocode)
        const streetNumber = await getGeocodePart(geocode, 'street_number', false)
        const route = await getGeocodePart(geocode, 'route', true)
        let city = await getGeocodePart(geocode, 'sublocality', false)
        if (!city) {
          city = await getGeocodePart(geocode, 'locality', false)
        }
        const province = await getGeocodePart(geocode, 'administrative_area_level_1', false)
        const postal = await getGeocodePart(geocode, 'postal_code', false)

        // Pass the data back up
        onGeoData({
          coordinates,
          formattedAddress: geocode.formatted_address,
          street: `${streetNumber} ${route}`,
          city,
          province,
          postal,
          placeId: geocode.place_id,
        })
      } catch (error) {
        console.error('😱 Error: ', error)
      }
    }

  const handleEnter = idx => () => {
    setCurrIndex(idx)
  }

  const handleLeave = () => {
    setCurrIndex(null)
  }

  const handleKeyDown = e => {
    if (!hasSuggestions || !acceptedKeys.includes(e.keyCode)) return

    if (e.keyCode === 9 || e.keyCode === 27) {
      dismissSuggestions()
      return
    }

    if (e.keyCode === 13) {
      e.preventDefault()
      if (currIndex === 'undefined') return // User hit enter without selecting an autocomplete
      handleSelect(data[currIndex])() // <--Curried function WOOT!
      return
    }

    let nextIndex

    if (e.keyCode === 38) {
      e.preventDefault()
      nextIndex = currIndex ?? data.length
      nextIndex = nextIndex > 0 ? nextIndex - 1 : null
    } else {
      nextIndex = currIndex ?? -1
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null
    }

    setCurrIndex(nextIndex)
    setValue(data[nextIndex] ? data[nextIndex].structured_formatting.main_text : cachedVal, false)
  }

  const renderSuggestions = () =>
    data.map((suggestion, idx) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={idx}
          id={`ex-list-item-${idx}`}
          css={idx === currIndex ? [listItem, listItemDarken] : listItem}
          onClick={handleSelect(suggestion)}
          onMouseEnter={handleEnter(idx)}
          role="option"
          aria-selected={idx === currIndex}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      )
    })

  return (
    <div className="relative" ref={ref}>
      <input
        {...props}
        type="text"
        value={value}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        disabled={!ready}
        autoComplete="off"
        aria-autocomplete="list"
        aria-controls="ex-list-box"
        aria-activedescendant={currIndex !== null ? `ex-list-item-${currIndex}` : null}
      />

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {hasSuggestions && (
        <ul onMouseLeave={handleLeave} css={listBox} role="listbox">
          {renderSuggestions()}
          <li css={googleLogo}>
            <img
              src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
              alt="Powered by Google"
            />
          </li>
        </ul>
      )}
      {!ready && <InputError>Something went wrong, try refreshing the browser window</InputError>}
    </div>
  )
}

PlacesAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onGeoData: PropTypes.func,
}

PlacesAutocomplete.defaultProps = {
  placeholder: 'Search for your address...',
  value: '',
  className: '',
  onGeoData: e => {},
  onChange: e => {},
}

export default PlacesAutocomplete
