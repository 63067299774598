const getGeocodePart = (result, part, useShortName = false) =>
  new Promise((resolve, reject) => {
    try {
      let geocodePart = null

      result.address_components.forEach(({ long_name, short_name, types }) => {
        if (types.includes(part)) geocodePart = useShortName ? short_name : long_name
      })

      resolve(geocodePart)
    } catch (error) {
      console.error(error)
      reject(error)
    }
  })

export default getGeocodePart
